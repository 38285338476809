.btn-xs {
  padding: 0 3px;
}

.select2-container--default .select2-selection .select2-selection__clear {
  right: -11px;
  z-index: 1000;
}
.select2-dropdown.select2-dropdown--below{
  min-width: 200px;
}
.select2-results__option[aria-selected=true] {
  display: none;
}
.select2-results__message {
  display: none;
}

.form-actions {
  margin-bottom: 0px;
}

.padding-10 {
  padding: 10px;
}
body.light-login {
  background: url('../../images/backgrounds/back2.jpg');
  background-size: cover;
}

// PER DATATABLE_NEW
//.dataTable>thead>tr>th[class*=sort]:after {
//  display: none;
//}

.dataTables_empty {
  text-align: center;
}

.dataTable [data-filter] .select2-selection__clear {
  color: red;
}
